//用户名匹配
export function nameRule(rule, value, callback) {
      //请输入4-10位昵称
      let reg = /(^[a-zA-Z0-9]{4,10}$)/
      if(value === ''){
          callback(new Error('请输入用户名'))
      }else if (!reg.test(value)){
          callback(new Error('请输入4-10位用户名'))
      }else {
          callback()
      }
}

export function passRule(rule, value, callback) {
    //请输入6-12位昵称
    let reg = /(^[a-zA-Z0-9]{6,12}$)/;
    if(value === ''){
        callback(new Error('请输入密码！'))
    }else if (!reg.test(value)){
        callback(new Error('请输入6-16位密码！'))
    }else {
        callback()
    }
}

export function checkCycleRule(rule, value, callback){
    let reg =  /^([0-9]*)$/
    if(value === ''){
        callback(new Error('请输入校验周期（天）！'))
    }else if (!reg.test(value)){
        callback(new Error('请输入一个整数！'))
    }else if (value <=30){
        callback(new Error('请输入一个大于30的整数！'))
    }else{
        callback()
    }
}

export function maintainCycleRule(rule, value, callback){
    let reg =  /^([0-9]*)$/
    if(value === ''){
        callback(new Error('请输入校验周期（天）！'))
    }else if (!reg.test(value)){
        callback(new Error('请输入一个整数！'))
    }else if (value <=7){
        callback(new Error('请输入一个大于7的整数！'))
    }else{
        callback()
    }
}

export function testCyckeRule(rule, value, callback){
    let reg =  /^([0-9]*)$/
    if(value === ''){
        callback(new Error('请输入试验周期（天）！'))
    }else if (!reg.test(value)){
        callback(new Error('请输入一个正整数！'))
    }else if (value <1){
        callback(new Error('请输入一个大于0的整数！'))
    }else{
        callback()
    }
}

export function indateRule(rule, value, callback){
    let reg =  /^([0-9]*)$/
    if(value === ''){
        callback(new Error('请输入保质期/有效期(年)！'))
    }else if (!reg.test(value)){
        callback(new Error('请输入一个正整数！'))
    }else if (value <1){
        callback(new Error('请输入一个大于0的整数！'))
    }else{
        callback()
    }
}

export  function codeRule(rule,value,callback){
    let reg =  /^\d{8,10}$/
    if(value === ''){
        callback(new Error('请录入设备条码！'))
    }else if (!reg.test(value)){
        callback(new Error('录入的设备条码长度不能少于8位并且不能超过10位的数字！'))
    }else{
        console.log("确定")
        callback()
    }
} 


export const deviceRule = {
    deviceCode: [{validator: codeRule,trigger: 'blur',required: true}],
    deviceNameCode: [{required: true, message: '请选择仪器名称'}],
    deviceRealName: [{required: true, message: '请输入实际名称'}],
    // deviceCode: [{required: true, message: '请输入仪器条码'}],
    optionValue: [{required: true,message: '请选择仪器分类'}],
    deviceType: [{required: true, message: '请输入仪器型号'}],
    deviceNum: [{required: true, message: '请输入仪器编号'}],
    purchaseDate: [{required: true, message: '请选择购置日期'}],
    manufacturer: [{required: true, message: '请输入生产厂家'}],
    isCheckoutOk:[{required: true}],
    // deviceinfoImg: [{required: true, message: '请上传设备详情图片'}],
    deviceStatus: [{required: true, message: '请选择仪器状态'}],
    isAttachment: [{required: true}],
    isCheck: [{required: true}],
    lastCheckDate: [{required: true, message: '请选择上次校验日期'}],
    checkCycle: [{validator: checkCycleRule,trigger: 'blur',required: true}],
    // checkDate: [{required: true}],
    isMaintain: [{required: true}],
    lastMaintainDate: [{required: true, message: '请选择上次保养日期'}],
    maintainCycle: [{validator: maintainCycleRule,trigger: 'blur',required: true}],
    whCode: [{required: true, message: '请选择库房'}],
    location: [{required: true, message: '请输入保存地点'}],
    source: [{required: true}],
    leaseePrice: [{pattern: /^[0-9]+(.[0-9]*)?$/, message: '请输入正确的金额'}],
    purchasePrice:[{pattern:/^[0-9]+(.[0-9]*)?$/, message: '请输入正确的金额'}]
};


export const sparePartRule = {
    spareName: [{required: true, message: '请输入备品备件名称'}],
    spareOptionValue: [{required: true, message: '请选择备品备件分类'}],
    spareType: [{required: true, message: '请输入规格型号'}],
    unit: [{required: true, message: '请输入单位'}],
    number: [{required: true, message: '请输入数量'}],
    price: [{pattern: /^[0-9]+(.[0-9]*)?$/,required: true, message: '请输入正确的金额'}],
    status: [{required: true}],
    whCode: [{required: true, message: '请选择存放仓库'}],
    location: [{required: true, message: '请输入存放位置'}],
    expirationDate: [{validator: indateRule,trigger: 'blur'}]
}

export const ToolInfoRule = {
    barcode:[{validator: codeRule,trigger: 'blur',required: true}],
    toolName: [{required: true, message: '请输入班组安全工器具名称'}],
    classCode: [{required: true, message: '请选择安全工器分类'}],
    whCode: [{required: true, message: '请选择存放仓库'}],
    location: [{required: true, message: '请输入存放位置'}],
    lastTestDate: [{required: true, message: '请选择上次试验日期'}],
    testCycke: [{validator: testCyckeRule,trigger: 'blur',required: true}],
    indate: [{validator: indateRule,trigger: 'blur'}]
}
export const TestInfoRule = {
    shType: [{required: true, message: '请选择试验类别'}],
    whCode: [{required: true, message: '请选择存放仓库'}],
    location: [{required: true, message: '请选择安全工器分类'}],
    testDate: [{required: true, message: '请输入存放位置'}],
    isRegular: [{required: true}]
}


export const AuxilInfoRule = {
    auxCode: [{validator: codeRule,trigger: 'blur',required: true}],
    classCode: [{required: true, message: '请选择分类'}],
    auxName: [{required: true, message: '请输入辅助工具名称'}],
    lastTestDate: [{required: true, message: '请选择上次试验日期'}],
    nextTestDate: [{required: true, message: '请选择下次试验日期'}],
    whCode: [{required: true, message: '请选择存放仓库'}],
    location: [{required: true, message: '请填写存放位置'}],
    produceDate: [{required: true, message: '请选择出厂日期'}],
    expirationDate: [{validator: indateRule,trigger: 'blur',required: true}]
}



