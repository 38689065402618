import service from "../service.js";

export const searchHouse = params => {
    return service({
        method: 'POST',
        url: '/warehouse/searchHouse',
        params
    })
}



export const addHouse = (data) => {
    return service({
        method: 'POST',
        url: '/warehouse/addHouse',
        data
    })
}

export const updateHouse = (data) => {
    return service({
        method: 'POST',
        url: '/warehouse/updateHouse',
        data
    })
}


export const queryDevice = (id) => {
    return service({
        method: 'GET',
        url: '/warehouse/queryHouse',
        params: {
            id
        }
    })
}

export const deleteHouse = (id) => {
    return service({
        method: 'GET',
        url: '/warehouse/deleteHouse',
        params: {
            id
        }
    })
}

export const getHouseAll = () => {
    return service({
      method: 'GET',
      url: '/warehouse/getHouseAll',
    })
  }


