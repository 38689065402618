import service from "../service.js";

//搜索安全工器具分类信息
export const searchToolClass = params => {
    return service({
      method: 'POST',
      url: '/toolClass/searchToolClass',
      params
    })
}

//获取所有分类信息
export const getToolClassAll = () => {
    return service({
      method: 'GET',
      url: '/toolClass/getToolClassAll',
    })
}

//根据编号查询分类
export const queryToolClass = (id) => {
    return service({
      method: 'GET',
      url: '/toolClass/queryToolClass',
      params: {
        id
      }
    })
  }



//删除备品备件分类信息
export const delToolClass = (id) => {
    return service({
      method: 'GET',
      url: '/toolClass/delToolClass',
      params: {
        id
      }
    })
  }

export const addToolClass = (data) => {
  return service({
    method: 'POST',
    url: '/toolClass/addToolClass',
    data
  })
}

export const updateToolClass = (data) => {
  return service({
    method: 'POST',
    url: '/toolClass/updateToolClass',
    data
  })
}


