import service from "../service.js";

//查询班组安全工器具信息
export const searchTeamInfo = params => {
    return service({
        method: 'POST',
        url: '/teamInfo/searchTeamInfo',
        params
    })
}

export const addTeamToolInfo = (data) => {
    return service({
        method: 'POST',
        url: '/teamInfo/addTeamToolInfo',
        data
    })
}

export const updateTeamToolInfo = (data) => {
    return service({
        method: 'POST',
        url: '/teamInfo/updateTeamToolInfo',
        data
    })
}

export const scrapTeamToolInfo = (id) => {
    return service({
        method: 'GET',
        url: '/teamInfo/scrapTeamToolInfo',
        params: {
            id
        }
    })
}

export const queryTeamToolInfo = (id) => {
    return service({
        method: 'GET',
        url: '/teamInfo/queryTeamToolInfo',
        params: {
            id
        }
    })
}

export const getToolStatus = () => {
    return service({
      method: 'GET',
      url: '/param/getToolStatus',
    })
}




